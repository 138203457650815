<svg
  width="12px"
  height="12px"
  viewBox="0 0 12 12"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="C-1.0-Sessions" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Registration-Forms&gt;Opened-Resitration" transform="translate(-1049, -224)">
      <g id="table/row-kid" transform="translate(132, 191)">
        <g id="Group-43" transform="translate(825, 28)">
          <g id="icon/info" transform="translate(92, 5)">
            <circle id="Oval" fill="#1A7DB8" cx="6" cy="6" r="6"></circle>
            <g id="Group-11" transform="translate(5, 2)">
              <line
                x1="1"
                y1="7"
                x2="1"
                y2="4"
                id="Path-3"
                stroke="#FFFFFF"
                stroke-width="2"
                stroke-linecap="round"
              ></line>
              <circle id="Oval" fill="#FFFFFF" cx="1" cy="1" r="1"></circle>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
