import { InjectionToken } from '@angular/core';
import { SessionEvent } from '@core/models/entities/session.models';
import { NapaDialogConfig } from '@shared/modules/dialog/models/dialog.models';
import { TimeSlotParams } from '@shared/modules/scheduler/models/time-slot/time-slot.models';

export const SCHEDULER_SESSION_ID: InjectionToken<string> = new InjectionToken('SCHEDULER_SESSION_ID');

export const SCHEDULER_SESSION_EVENT: InjectionToken<SessionEvent> = new InjectionToken('SCHEDULER_SESSION_EVENT');

export const SCHEDULER_TIME_SLOT_PARAMS: InjectionToken<TimeSlotParams> = new InjectionToken(
  'SCHEDULER_TIME_SLOT_PARAMS'
);

export const CONFIRM_REMOVE_THERAPIST_DIALOG_CONFIG: NapaDialogConfig = {
  title: 'Delete therapist?',
  body: 'Are you sure you want to delete therapist? This action can not be undone',
  hasPrimaryBtn: true,
  hasSecondaryBtn: true,
  closeOnButtonClick: true,
  secondaryBtnText: 'Cancel',
  primaryBtnText: 'Confirm',
};

export const THERAPIST_REMOVAL_DENIED_DIALOG_CONFIG: NapaDialogConfig = {
  title: `Therapist can't be deleted`,
  body: 'The scheduling process already started for this therapist.',
  hasSecondaryBtn: true,
  closeOnButtonClick: true,
  secondaryBtnText: 'Cancel',
};
