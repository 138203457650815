import { Directive, Signal, contentChildren, effect } from '@angular/core';
import { NapaFormTrackersGroup } from '../models/form-trackers-group.models';
import { BehaviorSubject, Observable, Subject, combineLatest, distinctUntilChanged, map, takeUntil } from 'rxjs';
import { NAPA_FORM_TRACKER } from '../constants/form-tracker.constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NapaFormTracker } from '../models/form-tracker.models';
import { NapaFormTrackerBase } from './form-tracker.base';

@UntilDestroy()
@Directive()
export abstract class NapaFormTrackersGroupBase extends NapaFormTrackerBase implements NapaFormTrackersGroup {
  private _trackers: Signal<ReadonlyArray<NapaFormTracker>> = contentChildren(NAPA_FORM_TRACKER, { descendants: true });

  private _destroyStatusHandlers$: Subject<void> = new Subject();

  private _lastIncomple$: BehaviorSubject<number> = new BehaviorSubject(-1);

  lastIncomple$: Observable<number> = this._lastIncomple$.asObservable();

  constructor(trackersGroup: NapaFormTrackersGroup | null) {
    super(trackersGroup);

    effect(() => {
      if (this.trackers()) this.handleTrackers(this.trackers());
    });
  }

  get trackers(): Signal<ReadonlyArray<NapaFormTracker>> {
    return this._trackers;
  }

  get lastIncomple(): number {
    return this._lastIncomple$.value;
  }

  private handleTrackers(trackers: ReadonlyArray<NapaFormTracker>): void {
    trackers.forEach((tracker: NapaFormTracker, index: number) => {
      tracker.index = index;
    });

    this.handleCompleteChanges(trackers);
  }

  private handleCompleteChanges(trackers: ReadonlyArray<NapaFormTracker>): void {
    this._destroyStatusHandlers$.next();

    combineLatest(
      trackers.map((step: NapaFormTracker, index: number) =>
        step.completed$
          .pipe(distinctUntilChanged())
          .pipe(map((isCompleted: boolean) => (isCompleted ? Infinity : index)))
      )
    )
      .pipe(untilDestroyed(this), takeUntil(this._destroyStatusHandlers$))
      .subscribe((indexes: number[]) => {
        const minIndex: number = Math.min(...indexes);

        if (minIndex === Infinity) this.completed = true;

        this._lastIncomple$.next(minIndex !== Infinity ? minIndex : -1);
      });
  }
}
