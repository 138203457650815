import { Injectable } from '@angular/core';
import {
  BlockHoursPayload,
  CreateNapaEventPayload,
  NapaEvent,
  UpdateNapaEventPayload,
} from '@core/models/entities/event.models';
import { HttpApi } from './http.api';
import { Observable } from 'rxjs';

const featurePrefix: string = 'session';

const featureSuffix: string = 'event';

const feature: string = `${featurePrefix}/${featureSuffix}`;

@Injectable({ providedIn: 'root' })
export class EventsApi {
  constructor(private readonly httpApi: HttpApi) {}

  get(id: string): Observable<NapaEvent[]> {
    return this.httpApi.get(`${feature}/${id}`);
  }

  create(sessionId: string, payload: CreateNapaEventPayload): Observable<NapaEvent> {
    return this.httpApi.post(`${featurePrefix}/${sessionId}/${featureSuffix}`, payload);
  }

  blockHours(sessionId: string, payload: BlockHoursPayload): Observable<NapaEvent> {
    return this.httpApi.post(`${featurePrefix}/${sessionId}/${featureSuffix}`, payload);
  }

  update(id: string, payload: UpdateNapaEventPayload): Observable<NapaEvent> {
    return this.httpApi.patch(`${feature}/${id}`, payload);
  }

  delete(id: string): Observable<boolean> {
    return this.httpApi.delete(`${feature}/${id}`);
  }
}
