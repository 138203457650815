import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'napa-circle-close-icon',
  standalone: true,
  imports: [],
  templateUrl: './circle-close-icon.component.html',
  styleUrl: './circle-close-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleCloseIconComponent {}
