import { Directive, Input } from '@angular/core';
import { NapaFormTracker } from '../models/form-tracker.models';
import { NapaFormTrackersGroup } from '../models/form-trackers-group.models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';

@UntilDestroy()
@Directive()
export abstract class NapaFormTrackerBase extends NapaFormTracker {
  set index(value: number) {
    this._index$.next(value);
  }
  get index(): number {
    return this._index$.value;
  }

  private _index$: BehaviorSubject<number> = new BehaviorSubject(0);

  readonly index$: Observable<number> = this._index$.asObservable();

  @Input() set completed(value: boolean) {
    this._completed$.next(value);
  }
  get completed(): boolean {
    return this._completed$.value;
  }

  private _completed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly completed$: Observable<boolean> = this._completed$.asObservable();

  set disabled(value: boolean) {
    this._disabled$.next(value);
  }
  get disabled(): boolean {
    return this._disabled$.value;
  }

  private _disabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly disabled$: Observable<boolean> = this._disabled$.asObservable();

  constructor(trackersGroup: NapaFormTrackersGroup | null) {
    super();

    if (trackersGroup) {
      this.manageDisabledState(trackersGroup);
    }
  }

  private manageDisabledState(group: NapaFormTrackersGroup): void {
    this.index$
      .pipe(untilDestroyed(this))
      .pipe(switchMap(() => group.lastIncomple$))
      .subscribe((lastIncomple: number) => {
        this.disabled = lastIncomple < 0 ? false : lastIncomple < this.index;
      });
  }
}
