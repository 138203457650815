<svg
  width="16px"
  height="16px"
  viewBox="0 0 16 16"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="icon/time-grey" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <rect id="Rectangle" x="0" y="0" width="16" height="16"></rect>
    <circle id="Oval" stroke="#8E9498" stroke-width="2" cx="8" cy="8" r="7"></circle>
    <line x1="8" y1="8" x2="8" y2="4" id="Path-12" stroke="#8E9498" stroke-width="2" stroke-linecap="round"></line>
    <line x1="8" y1="8" x2="11" y2="8" id="Path-13" stroke="#8E9498" stroke-width="2" stroke-linecap="round"></line>
  </g>
</svg>
