export const MODALITIES_TYPES: Record<string, string> = {
  DMI: 'Dynamic Movement Intervention',
  NS: 'NeuroSuit (2 hour block)',
  OT: 'Occupational Therapy',
  PT: 'Physical Therapy',
  SPD: 'SpiderCage',
  Fx: 'Functional Hour (either PT or OT)',
  AAC: 'Augmentative and Alternative Communication',
  FD: 'Feeding',
  ST: 'Speech Therapy',
  'AAC/FD': 'Augmentative and Alternative Communication and Feeding',
  VS: 'VitalStim',
};
