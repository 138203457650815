import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';
import { InfoIconComponent } from '../icons/info-icon/info-icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MODALITIES_TYPES } from '@shared/constants/modalities.constants';

@Component({
  selector: 'napa-modalities',
  standalone: true,
  imports: [CommonModule, InfoIconComponent, MatTooltipModule],
  templateUrl: './modalities.component.html',
  styleUrl: './modalities.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalitiesComponent {
  modalitiesTypes = MODALITIES_TYPES;
  maxLength: InputSignal<number> = input(Infinity);
  showTooltip: InputSignal<boolean> = input(false);

  modalities: InputSignal<string[]> = input.required();

  get modalitiesTooltip(): string {
    return this.modalities().join(', ');
  }
}
