<div class="napa-modality-options-list">
  @for (modality of modalities() | slice: 0 : maxLength(); track modality) {
    <span
      class="napa-modality-option napa-modality-option--green"
      [matTooltip]="showTooltip() ? modalitiesTypes[modality] : ''"
    >
      {{ modality }}
    </span>
  }

  @if (modalities().length > maxLength()) {
    <div class="napa-hidden-modalities-info">
      <div class="napa-hidden-modalities-info__count">{{ modalities().length - maxLength() }}+</div>

      <napa-info-icon [matTooltip]="modalitiesTooltip"></napa-info-icon>
    </div>
  }
</div>
