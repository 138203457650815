<div class="form-tracker-progress-bar-container">
  <div class="form-tracker-progress-bar">
    <div class="form-tracker-complete-indicator"></div>

    <div class="form-tracker-pass-indicator"></div>
  </div>
</div>

<div class="form-tracker-content">
  <ng-content></ng-content>
</div>
