import { NapaDialogConfig } from '@shared/modules/dialog/models/dialog.models';

export const MODALITY_MISMATCH_DIALOG_CONFIG: NapaDialogConfig = {
  title: `Patient's modality doesn't meet therapist's modality`,
  body: 'Are you sure you want to continue?',
  hasPrimaryBtn: true,
  hasSecondaryBtn: true,
  closeOnButtonClick: true,
  secondaryBtnText: 'Cancel',
  primaryBtnText: 'Confirm',
};
