import { Directive, effect, input, InputSignal, TemplateRef, ViewContainerRef } from '@angular/core';
import { Role } from '@core/constants';
import { ProfileApi } from '@core/services/api/profile.api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthApi } from '@core/services/api/auth.api';

@UntilDestroy()
@Directive({
  selector: '[napaCanRender]',
  standalone: true,
})
export class CanRenderDirective {
  roles: InputSignal<Role[]> = input.required({ alias: 'napaCanRender' });

  constructor(
    private templateRef: TemplateRef<never>,
    private viewContainer: ViewContainerRef,
    private profileApi: ProfileApi,
    private authApi: AuthApi
  ) {
    this.checkRenderCondition();
  }

  private checkRenderCondition() {
    effect(() => {
      this.viewContainer.clear(); // Clear view by default

      if (!this.authApi.isAuthorized()) {
        return;
      }

      this.profileApi.user$.pipe(untilDestroyed(this)).subscribe((user) => {
        if (this.roles().includes(user?.role as Role)) {
          this.viewContainer.createEmbeddedView(this.templateRef); // Render template if authorized
        } else {
          this.viewContainer.clear(); // Clear view if role doesn't match
        }
      });
    });
  }
}
