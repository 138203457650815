import { Directive } from '@angular/core';
import { AbstractControl, FormControlStatus } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, startWith } from 'rxjs';

@UntilDestroy()
@Directive()
export abstract class AbstractControlTracker {
  abstract get control(): AbstractControl;

  private readonly _isValid$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly isValid$: Observable<boolean> = this._isValid$.asObservable();

  handleControlStatusChanges(): void {
    this.control.statusChanges
      .pipe(untilDestroyed(this))
      .pipe(startWith(this.control.status))
      .subscribe((status: FormControlStatus) => this._isValid$.next(status !== 'INVALID'));
  }
}
