export abstract class NapaForm<P = unknown> {
  abstract get payload(): P;

  get storePayload() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.payload as any;
  }

  abstract get isValid(): boolean;

  abstract markAllAsTouched(): void;
}
