import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Host,
  Inject,
  input,
  InputSignal,
  Optional,
  output,
  OutputEmitterRef,
  Self,
} from '@angular/core';
import {
  WLCM_FORM_CONTROL,
  WLCM_FORM_CONTROL_PROVIDER,
  WlcmFormControl,
  WlcmFormsModule,
  WlcmPlacesApi,
} from '@wlcm/angular/forms';
import { ControlContainer, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ArrowDownBlueIconComponent } from '@shared/components/icons/arrow-down-blue-icon/arrow-down-blue-icon.component';
import { PaginatedData, QueryParams, WlcmAutocompleteOption, WlcmOption } from '@wlcm/angular/core';
import { FamilyMemberForm } from '../../models/family-member-form.models';
import { ParentFormModel, ParentFormPayload, ParentFormValue } from '../../models/parent-form.models';
import { WlcmPhoneInputModule } from '@wlcm/angular/phone-input';
import { Parent } from '@core/models/entities/parent.models';
import { MatSelectChange } from '@angular/material/select';
import { ParentApi } from '@core/services/api/parent.api';
import { patchSelectableParentForm, transformToParentPayload } from '../../utils';
import { map, Observable } from 'rxjs';
import { ADMIN_ROLES } from '@core/constants';
import { CanRenderDirective } from '@shared/directives/can-render.directive';

@Component({
  selector: 'napa-parent-form',
  standalone: true,
  imports: [WlcmFormsModule, WlcmPhoneInputModule, ReactiveFormsModule, ArrowDownBlueIconComponent, CanRenderDirective],
  templateUrl: './parent-form.component.html',
  styleUrl: './parent-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WLCM_FORM_CONTROL_PROVIDER],
})
export class ParentFormComponent extends FamilyMemberForm<ParentFormModel, ParentFormPayload> {
  selected: OutputEmitterRef<Parent> = output();

  parentOptions: InputSignal<WlcmOption[]> = input<WlcmOption[]>([]);

  protected readonly AdminRoles = ADMIN_ROLES;

  constructor(
    private parentApi: ParentApi,
    private placesApi: WlcmPlacesApi,
    protected override changeDetector: ChangeDetectorRef,
    @Inject(WLCM_FORM_CONTROL) protected override wlcmFormControl: WlcmFormControl,
    @Optional() @Host() @Self() protected override controlContainer: ControlContainer
  ) {
    super(changeDetector, wlcmFormControl, controlContainer);
  }

  get payload(): ParentFormPayload {
    return transformToParentPayload(this.form.value as ParentFormValue);
  }

  override get storePayload(): ParentFormValue {
    return this.form.value as ParentFormValue;
  }

  get isValid(): boolean {
    return this.form.valid;
  }

  get fullNameControl(): FormControl {
    return this.form.controls.full_name;
  }

  handleSelectedParent(option: MatSelectChange): void {
    const parent: Parent = option.value.data;

    this.selected.emit(parent);

    return patchSelectableParentForm(this.form, parent, false);
  }

  markAllAsTouched(): void {
    this.form.markAllAsTouched();

    this.changeDetector.markForCheck();
  }

  getParents = (params: QueryParams): Observable<PaginatedData<WlcmAutocompleteOption>> => {
    return this.parentApi.getPaginated(params).pipe(
      map((paginatedData: PaginatedData<Parent>) => {
        return {
          ...paginatedData,
          data: paginatedData.data.map(
            (parent: Parent) => new WlcmAutocompleteOption(parent._id, parent.full_name, parent.full_name, parent)
          ),
        };
      })
    );
  };

  getPlaces = (params: QueryParams): Observable<PaginatedData<WlcmAutocompleteOption>> => {
    return this.placesApi.getPlacesPaginated(params.query || '');
  };

  validateControlContainer(controlContainer: ControlContainer): void {
    if (!controlContainer || !(controlContainer?.control instanceof FormGroup)) {
      throw new Error('FormGroup<ParentFormModel> is not provided.');
    }
  }
}
