import { ChangeDetectionStrategy, Component, Inject, Optional, SkipSelf, forwardRef } from '@angular/core';
import { NAPA_FORM_TRACKER, NAPA_FORM_TRACKERS_GROUP } from '../../constants/form-tracker.constants';
import { NapaFormTrackersGroupBase } from '../../directives/form-trackers-group.base';
import { NapaFormTrackersGroup } from '../../models';

@Component({
  selector: 'napa-form-trackers-group',
  exportAs: 'napaFormTrackersGroup',
  standalone: true,
  imports: [],
  templateUrl: './form-trackers-group.component.html',
  styleUrl: './form-trackers-group.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NAPA_FORM_TRACKER, useExisting: forwardRef(() => FormTrackersGroupComponent) },
    { provide: NAPA_FORM_TRACKERS_GROUP, useExisting: forwardRef(() => FormTrackersGroupComponent) },
  ],
})
export class FormTrackersGroupComponent extends NapaFormTrackersGroupBase {
  constructor(@Optional() @SkipSelf() @Inject(NAPA_FORM_TRACKERS_GROUP) parentGroup: NapaFormTrackersGroup) {
    super(parentGroup);
  }
}
