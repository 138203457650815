import { Directive, Host } from '@angular/core';
import { WlcmSelectComponent } from '@wlcm/angular/forms';
import { TimeRangePoint } from '../models/time-range-point.models';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[napaStartTime]',
  standalone: true,
})
export class StartTimeDirective extends TimeRangePoint {
  constructor(
    @Host() protected override _control: NgControl,
    @Host() protected override _selectComponent: WlcmSelectComponent
  ) {
    super(_control, _selectComponent);
  }
}
