import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormTrackerComponent, FormTrackersGroupComponent } from './components';
import { TrackReactiveControlDirective, TrackReactiveFormDirective } from './directives';

const publicComponents: Type<unknown>[] = [FormTrackerComponent, FormTrackersGroupComponent];

const publicDirectives: Type<unknown>[] = [TrackReactiveFormDirective, TrackReactiveControlDirective];

@NgModule({
  declarations: [],
  imports: [CommonModule, ...publicComponents, ...publicDirectives],
  exports: [...publicComponents, ...publicDirectives],
})
export class FormTrackerModule {}
