import { Observable } from 'rxjs';
import { SchedulerEvent, SchedulerEventId } from '../scheduler/scheduler.models';

export type GridTimeSlotContainerId = string;

export abstract class GridAdapter {
  /* Add one event to the collection */
  abstract addOne(event: SchedulerEvent): void;

  /* Add multiple events to the collection */
  abstract addMany(events: SchedulerEvent[]): void;

  /* Replace current collection with provided collection */
  abstract setAll(event: SchedulerEvent[]): void;

  /* Update one event in the collection */
  abstract updateOne(id: SchedulerEventId, event: SchedulerEvent): void;

  /* Remove one event from the collection */
  abstract removeOne(id: SchedulerEventId): void;

  /* Remove one event from the collection */
  abstract removeColumnEvents(id: string): void;

  /* Retrieves events of the specific column */
  abstract getColumnEvents(id: string): SchedulerEvent[];

  abstract ready: Observable<boolean>;
}
