import { Directive, Host, OnInit, Self, forwardRef } from '@angular/core';
import { AbstractControlTracker } from '../models/track-abstract-control.models';
import { AbstractControl, ControlContainer } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[napaTrackReactiveForm]',
  standalone: true,
  providers: [{ provide: AbstractControlTracker, useExisting: forwardRef(() => TrackReactiveFormDirective) }],
})
export class TrackReactiveFormDirective extends AbstractControlTracker implements OnInit {
  constructor(@Host() @Self() private controlContainer: ControlContainer) {
    super();
  }

  ngOnInit(): void {
    this.handleControlStatusChanges();
  }

  get control(): AbstractControl {
    return this.controlContainer.control!;
  }
}
