import { DATE_RANGE_PERIOD } from '@core/constants/date-options.constants';
import { NapaDateRange, NapaDateRangePeriod } from '@core/models/date.models';
import { endOfMonth, endOfWeek, format, startOfMonth, startOfWeek } from 'date-fns';

const formatValue: string = 'MM/dd/yyyy hh:mm';

export function getThisWeekPeriod(): NapaDateRange {
  return { start: startOfWeek(new Date()), end: endOfWeek(new Date()) };
}

export function getThisMonthPeriod(): NapaDateRange {
  return { start: startOfMonth(new Date()), end: endOfMonth(new Date()) };
}

export function getPeriod(range: NapaDateRange): NapaDateRangePeriod | null {
  let period: NapaDateRangePeriod | null = null;

  for (const rangePeriod in DATE_RANGE_PERIOD) {
    const key: NapaDateRangePeriod = rangePeriod as NapaDateRangePeriod;

    const periodRange: NapaDateRange = DATE_RANGE_PERIOD[key];

    if (isRangeEqual(range, periodRange)) {
      period = key;

      break;
    }
  }

  return period;
}

function isRangeEqual(rangeA: NapaDateRange, rangeB: NapaDateRange): boolean {
  return (
    format(rangeA.start, formatValue) === format(rangeB.start, formatValue) &&
    format(rangeA.end, formatValue) === format(rangeB.end, formatValue)
  );
}
