import { NgControl } from '@angular/forms';
import { WlcmSelectComponent } from '@wlcm/angular/forms';
import { Observable, of } from 'rxjs';

export abstract class TimeRangePoint {
  constructor(
    protected _control: NgControl,
    protected _selectComponent: WlcmSelectComponent
  ) {}

  get selectComponent(): WlcmSelectComponent {
    return this._selectComponent;
  }

  get value(): string {
    return this._control.value;
  }

  get valueChanges(): Observable<string> {
    return this._control.valueChanges || of(null);
  }
}
