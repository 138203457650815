import { FormControl, FormGroup, Validators } from '@angular/forms';
import { nameValidators } from '@core/constants/forms/form-validators.constants';
import { createIwoFormGroup } from '@shared/modules/input-with-options/utils/input-with-options';
import { CreateKidFormConfig, KidFormModel, KidFormPayload, KidFormValue } from '../models/kid-form.models';
import { Child } from '@core/models/entities/child.models';
import { WlcmAutocompleteOption } from '@wlcm/angular/core';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { TShirtSize, TShirtSizeMap } from '@core/constants';

/**
 *   *No need to write rules for all purposes if validators not required
 *
 * @see KidFormComponent
 * */
export const createKidForm = (config?: CreateKidFormConfig): FormGroup<KidFormModel> => {
  const form: FormGroup<KidFormModel> = new FormGroup<KidFormModel>({
    id: new FormControl(null),
    full_name: new FormControl('', [Validators.required, ...nameValidators]),
    birthdate: new FormControl('', [Validators.required]),
    height: createIwoFormGroup(['', 'in'], [[Validators.required], [Validators.required], [Validators.required]]),
    weight: createIwoFormGroup(['', 'lb'], [[Validators.required], [Validators.required], [Validators.required]]),
    t_shirt: new FormControl(''),
    diagnosis: new FormControl(''),
    time_from: new FormControl(''),
    break: new FormControl(''),
  });

  if (['sign-up', 'profile'].includes(config?.purpose as string)) {
    form.controls.diagnosis?.setValidators([
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(100_000),
    ]);
  }

  if (config?.purpose === 'checklist') {
    form.controls.t_shirt?.setValidators([Validators.required]);
    form.controls.time_from?.setValidators([Validators.required]);
    form.controls.break?.setValidators([Validators.required]);
  }

  if (config?.mode === 'select') {
    form.disable();
    form.controls.full_name.enable();
    form.controls.full_name.setValidators([Validators.required]);
  }

  return form;
};

export const patchSelectableKidForm = (
  form: FormGroup<KidFormModel>,
  kid: Child,
  patchFullName: boolean = true
): void => {
  if (patchFullName) {
    form.controls.full_name.setValue(new WlcmAutocompleteOption(kid._id, kid.full_name, kid.full_name, kid));
  }

  form.controls.birthdate.setValue(toZonedTime(kid.birthdate, 'UTC'));
  form.controls.height.patchValue({ value: kid.height.toString(), option: kid.height_unit });
  form.controls.weight.patchValue({ value: kid.weight.toString(), option: kid.weight_unit });
  if (kid.t_shirt) form.controls.t_shirt?.patchValue({ value: kid.t_shirt, viewValue: TShirtSizeMap[kid.t_shirt] });
  form.controls.break?.patchValue(kid.break);
  form.controls.break?.patchValue(kid.preferred_start_time?.time_from);
};

export const transformToKidFormValue = (kid: Child): KidFormValue => {
  const payload: Partial<KidFormValue> = {
    id: kid._id,
    full_name: kid.full_name,
    birthdate: toZonedTime(kid.birthdate, 'UTC'),
    diagnosis: kid.diagnosis,
    break: kid.break,
    time_from: kid.preferred_start_time?.time_from as string,
  };

  if (kid.t_shirt) {
    payload.t_shirt = { value: kid.t_shirt, viewValue: TShirtSizeMap[kid.t_shirt] };
  }

  payload.height = { value: kid.height.toString(), option: kid.height_unit };
  payload.weight = { value: kid.weight.toString(), option: kid.weight_unit };

  return payload as KidFormValue;
};

export const transformToChildPayload = (formPayload: KidFormValue): KidFormPayload => {
  const payload: Partial<KidFormPayload> = {
    id: formPayload.id,
    full_name: (formPayload.full_name as unknown as { value: string }).value || formPayload.full_name,
    birthdate: format(formPayload.birthdate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
    t_shirt: formPayload.t_shirt?.value as TShirtSize,
    diagnosis: formPayload.diagnosis,
    break: formPayload.break,
  };

  if (formPayload.time_from) {
    payload.preferred_start_time = { time_from: formPayload.time_from };
  }

  payload.weight = Number(formPayload.weight.value);
  payload.weight_unit = formPayload.weight.option;

  payload.height = Number(formPayload.height.value);
  payload.height_unit = formPayload.height.option;

  return payload as KidFormPayload;
};
