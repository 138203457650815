import { Directive, Host, OnInit, Self, forwardRef } from '@angular/core';
import { AbstractControlTracker } from '../models/track-abstract-control.models';
import { AbstractControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[napaTrackReactiveControl]',
  standalone: true,
  providers: [{ provide: AbstractControlTracker, useExisting: forwardRef(() => TrackReactiveControlDirective) }],
})
export class TrackReactiveControlDirective extends AbstractControlTracker implements OnInit {
  constructor(@Host() @Self() private ngControl: NgControl) {
    super();
  }

  ngOnInit(): void {
    this.handleControlStatusChanges();
  }

  get control(): AbstractControl {
    return this.ngControl.control!;
  }
}
