import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ModalitiesManagementApi {
  private readonly _modalitiesMap = new BehaviorSubject<Map<string, string[]> | null>(null);

  modalitiesMap: Observable<Map<string, string[]> | null> = this._modalitiesMap.asObservable();

  setModalitiesMap(modalitiesMap: Map<string, []>): void {
    this._modalitiesMap.next(modalitiesMap);
  }

  addItemToMap(key: string, item: string[]): void {
    const currentMap = this._modalitiesMap.getValue();

    if (currentMap) {
      if (currentMap.has(key)) {
        const existingArray = currentMap.get(key) || [];
        existingArray.push(...item);
        currentMap.set(key, existingArray);
      } else {
        currentMap.set(key, [...item]);
      }

      this._modalitiesMap.next(new Map(currentMap));
    } else {
      const newMap = new Map<string, string[]>();
      newMap.set(key, [...item]);
      this._modalitiesMap.next(newMap);
    }
  }

  removeItemsFromMap(key: string, items: string[]): void {
    const currentMap = this._modalitiesMap.getValue();

    if (currentMap) {
      if (currentMap.has(key)) {
        const existingArray = currentMap.get(key);

        if (existingArray) {
          items.forEach((item) => {
            const index = existingArray.indexOf(item);
            if (index > -1) {
              existingArray.splice(index, 1);
            }
          });

          if (existingArray.length === 0) {
            currentMap.delete(key);
          }

          this._modalitiesMap.next(new Map(currentMap));
        }
      }
    }
  }

  removeAndAddNewItems(key: string, prevItems: string[], item: string[]): void {
    const currentMap = this._modalitiesMap.getValue();

    if (currentMap) {
      if (currentMap.has(key)) {
        const removedItems = prevItems.filter((prevItem) => !item.includes(prevItem));

        if (removedItems.length) {
          const currentArray = currentMap.get(key) || [];

          removedItems.forEach((removedItem) => {
            const index = currentArray.indexOf(removedItem);
            if (index !== -1) {
              currentArray.splice(index, 1);
            }
          });

          currentMap.set(key, currentArray);
        }

        const existingArray = currentMap.get(key) || [];

        existingArray.push(...item);

        currentMap.set(key, existingArray);
      } else {
        currentMap.set(key, [...item]);
      }

      this._modalitiesMap.next(new Map(currentMap));
    } else {
      const newMap = new Map<string, string[]>();
      newMap.set(key, [...item]);
      this._modalitiesMap.next(newMap);
    }
  }
}
