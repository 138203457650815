import { NapaEvent } from '@core/models/entities/event.models';

export interface SessionSchedulerColumn {}

export enum SessionSchedulerEventType {
  RESERVED = 'reserved',
  UNAVAILABLE = 'unavailable',
  BLOCKED = 'blocked',
}

export interface SessionSchedulerEvent {
  event?: NapaEvent;

  type: SessionSchedulerEventType;
}
