import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class SessionSchedulerApi {
  private readonly _childAssigned: Subject<void> = new Subject();

  readonly childAssigned: Observable<void> = this._childAssigned.asObservable();

  private readonly _childRemoved: Subject<void> = new Subject();

  readonly childRemoved: Observable<void> = this._childRemoved.asObservable();

  notifyChildAssignment(): void {
    this._childAssigned.next();
  }

  notifyChildRemoval(): void {
    this._childRemoved.next();
  }
}
