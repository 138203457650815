import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  NgZone,
  Signal,
  contentChildren,
  effect,
  forwardRef,
} from '@angular/core';
import { AbstractControlTracker } from '../../models/track-abstract-control.models';
import { NAPA_FORM_TRACKER, NAPA_FORM_TRACKERS_GROUP } from '../../constants';
import { NapaFormTrackerBase } from '../../directives/form-tracker.base';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject, combineLatest, merge, switchMap, takeUntil, tap } from 'rxjs';
import { NapaFormTrackersGroup } from '../../models';
import { CommonModule } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'napa-form-tracker',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './form-tracker.component.html',
  styleUrl: './form-tracker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NAPA_FORM_TRACKER, useExisting: forwardRef(() => FormTrackerComponent) }],
})
export class FormTrackerComponent extends NapaFormTrackerBase {
  private _controlTrackers: Signal<ReadonlyArray<AbstractControlTracker>> = contentChildren(AbstractControlTracker, {
    descendants: true,
  });

  private _controlTrackersUpdated$: Subject<void> = new Subject();

  constructor(
    private zone: NgZone,
    private elementRef: ElementRef<HTMLElement>,
    @Inject(NAPA_FORM_TRACKERS_GROUP) private trackersGroup: NapaFormTrackersGroup
  ) {
    super(trackersGroup);

    this.handleStateChanges();

    effect(() => {
      this._controlTrackersUpdated$.next();

      this.handleControlsStatusChanges(this._controlTrackers());
    });
  }

  private handleStateChanges(): void {
    this.zone.runOutsideAngular(() => {
      this.index$
        .pipe(untilDestroyed(this))
        .pipe(
          switchMap(() => merge(this.handleCompleteChanges(), this.handlePendingChanges(), this.handleDisableChanges()))
        )
        .subscribe();
    });
  }

  private handleCompleteChanges(): Observable<unknown> {
    return this.completed$.pipe(
      tap((isCompleted: boolean) => {
        if (isCompleted) {
          return this.elementRef.nativeElement.classList.add('form-tracker-completed');
        }

        if (this.elementRef.nativeElement.classList.contains('form-tracker-completed')) {
          this.elementRef.nativeElement.classList.remove('form-tracker-completed');
        }
      })
    );
  }

  private handlePendingChanges(): Observable<unknown> {
    return this.trackersGroup.lastIncomple$.pipe(
      tap((lastIncomple: number) => {
        if (lastIncomple === this.index) {
          return this.elementRef.nativeElement.classList.add('form-tracker-pending');
        }

        if (this.elementRef.nativeElement.classList.contains('form-tracker-pending')) {
          this.elementRef.nativeElement.classList.remove('form-tracker-pending');
        }
      })
    );
  }

  private handleDisableChanges(): Observable<unknown> {
    return this.disabled$.pipe(
      tap((isDisabled: boolean) => {
        if (isDisabled) {
          return this.elementRef.nativeElement.classList.add('form-tracker-disabled');
        }

        if (this.elementRef.nativeElement.classList.contains('form-tracker-disabled')) {
          this.elementRef.nativeElement.classList.remove('form-tracker-disabled');
        }
      })
    );
  }

  private handleControlsStatusChanges(trackers: ReadonlyArray<AbstractControlTracker>): void {
    combineLatest(trackers.map((tracker: AbstractControlTracker) => tracker.isValid$))
      .pipe(untilDestroyed(this), takeUntil(this._controlTrackersUpdated$))
      .subscribe((statuses: boolean[]) => {
        const isValid: boolean = statuses.every(Boolean);

        if (this.completed !== isValid) {
          this.completed = isValid;
        }
      });
  }
}
