import { ChangeDetectionStrategy, Component, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { GridAdapter } from '@shared/modules/scheduler/models/grid-adaper/grid-adaper.models';
import { AssignKidDialogComponent } from '../../assign-kid-dialog/assign-kid-dialog.component';
import {
  SCHEDULER_SESSION_EVENT,
  SCHEDULER_SESSION_ID,
  SCHEDULER_TIME_SLOT_PARAMS,
} from '../../../constants/session-scheduler.constants';
import { AssignKidDialogConfig } from '../../../models/assign-kid.models';
import { DialogApi } from '@shared/modules/dialog/services/dialog.api';
import { SessionEvent } from '@core/models/entities/session.models';
import { TimeSlotParams } from '@shared/modules/scheduler/models/time-slot/time-slot.models';
import { addMinutes } from '../../../utils/session-scheduler.utils';

@Component({
  selector: 'napa-empty-slot',
  host: { class: 'napa-empty-slot' },
  templateUrl: './empty-slot.component.html',
  styleUrl: './empty-slot.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class EmptySlotComponent {
  constructor(
    private injector: Injector,
    private dialogApi: DialogApi,
    private gridAdapter: GridAdapter,
    @Inject(SCHEDULER_SESSION_ID) private sessionId: string,
    @Inject(SCHEDULER_SESSION_EVENT) private sessionEvent: SessionEvent,
    @Inject(SCHEDULER_TIME_SLOT_PARAMS) private timeSlotParams: TimeSlotParams
  ) {}

  assignKid(): void {
    this.dialogApi.openCustomPopup(AssignKidDialogComponent, this.assignKidDialogConfig, {
      maxWidth: '384px',
      autoFocus: false,
      injector: this.assignKidDialogInjector,
    });
  }

  private get assignKidDialogConfig(): AssignKidDialogConfig {
    return {
      data: {
        sessionId: this.sessionId,

        event: this.sessionEvent,

        start: this.timeSlotParams.startRowId,

        end: addMinutes(this.timeSlotParams.endRowId, 30),
      },
    };
  }
  private get assignKidDialogInjector(): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [{ provide: GridAdapter, useValue: this.gridAdapter }],
    });
  }
}
