import { Observable } from 'rxjs';
import { NapaFormTracker } from './form-tracker.models';
import { Signal } from '@angular/core';

export abstract class NapaFormTrackersGroup extends NapaFormTracker {
  abstract lastIncomple$: Observable<number>;

  abstract get lastIncomple(): number;

  abstract get trackers(): Signal<ReadonlyArray<NapaFormTracker>>;
}
