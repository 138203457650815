import { NapaDatePeriod, NapaDateRange, NapaDateRangePeriod } from '@core/models/date.models';
import { WlcmOption } from '@wlcm/angular/core';
import { getThisMonthPeriod, getThisWeekPeriod } from '@core/utils/period.utils';
import { parseTimePoint } from '@features/sessions/modules/session-scheduler/utils/session-scheduler.utils';
import { add, eachHourOfInterval, endOfDay, format, set, startOfDay } from 'date-fns';

const generateTimeOptions = (): WlcmOption[] => {
  const hours: Date[] = eachHourOfInterval({
    start: set(startOfDay(new Date()), { hours: 8 }),
    end: endOfDay(new Date()),
  });

  const timePoints: Date[] = [];

  hours.forEach((hour: Date) => {
    timePoints.push(hour, add(hour, { minutes: 30 }));
  });

  return timePoints.map((date: Date) => new WlcmOption(format(date, 'H:mm'), format(date, 'h:mmaaa')));
};

// export const TIME_OPTIONS: WlcmOption[] = generateTimeOptions();
export const TIME_OPTIONS: WlcmOption[] = [
  new WlcmOption('7:00', '7:00am'),
  new WlcmOption('8:00', '8:00am'),
  new WlcmOption('9:00', '9:00am'),
  new WlcmOption('10:00', '10:00am'),
  new WlcmOption('11:00', '11:00am'),
  new WlcmOption('12:00', '12:00pm'),
  new WlcmOption('12:30', '12:30pm'),
  new WlcmOption('13:30', '1:30pm'),
  new WlcmOption('14:30', '2:30pm'),
  new WlcmOption('15:30', '3:30pm'),
];

export const SESSION_TIME_OPTIONS: WlcmOption[] = TIME_OPTIONS.filter((option: WlcmOption) => {
  const { hours } = parseTimePoint(option.value as string);
  return hours >= 8 && hours <= 19;
});

export const SCHEDULER_SESSION_TIME_OPTIONS: WlcmOption[] = generateTimeOptions().filter((option: WlcmOption) => {
  const { hours } = parseTimePoint(option.value as string);
  return hours >= 8 && hours <= 19;
});

export const DATE_PERIOD_OPTIONS: WlcmOption[] = [
  { value: NapaDatePeriod.ALL_DATES, viewValue: 'All Dates' },
  { value: NapaDatePeriod.THIS_WEEK, viewValue: 'This Week' },
  { value: NapaDatePeriod.THIS_MONTH, viewValue: 'This Month' },
];

export const DATE_RANGE_PERIOD: { [key in NapaDateRangePeriod]: NapaDateRange } = {
  [NapaDatePeriod.THIS_WEEK]: getThisWeekPeriod(),
  [NapaDatePeriod.THIS_MONTH]: getThisMonthPeriod(),
};
