import { AfterContentInit, Directive, Signal, contentChild } from '@angular/core';
import { Observable, startWith, observeOn, asyncScheduler, merge, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StartTimeDirective } from './start-time.directive';
import { EndTimeDirective } from './end-time.directive';
import { WlcmOption } from '@wlcm/angular/core';

@UntilDestroy()
@Directive({
  selector: '[napaTimeRangeManager]',
  exportAs: 'NapaTimeRangeManager',
  standalone: true,
})
export class TimeRangeManagerDirective implements AfterContentInit {
  startTime: Signal<StartTimeDirective> = contentChild.required(StartTimeDirective);

  endTime: Signal<EndTimeDirective> = contentChild.required(EndTimeDirective);

  ngAfterContentInit(): void {
    this.handleTimeValueChanges();
  }

  isStartTimeOptionDisabled = (option: WlcmOption | string): boolean => {
    if (!this.endTimeValue) return false;

    let time: string = option as string;

    if (option instanceof Object) time = option.value as string;

    if (parseInt(this.endTimeValue) > parseInt(time)) return false;

    return true;
  };

  isEndTimeOptionDisabled = (option: WlcmOption | string): boolean => {
    if (!this.startTimeValue) return false;

    let time: string = option as string;

    if (option instanceof Object) time = option.value as string;

    if (parseInt(this.startTimeValue) < parseInt(time)) return false;

    return true;
  };

  get startTimeValue(): string {
    return this.startTime().value;
  }

  get endTimeValue(): string {
    return this.endTime().value;
  }

  private handleTimeValueChanges(): void {
    const startTimeChanges: Observable<unknown> = this.startTime().valueChanges.pipe(
      startWith(this.startTimeValue),
      observeOn(asyncScheduler)
    );

    const endTimeChanges: Observable<unknown> = this.endTime().valueChanges.pipe(
      startWith(this.endTimeValue),
      observeOn(asyncScheduler)
    );

    merge(
      startTimeChanges.pipe(tap(() => this.endTime().selectComponent.updateOptionsStatus())),
      endTimeChanges.pipe(tap(() => this.startTime().selectComponent.updateOptionsStatus()))
    )
      .pipe(untilDestroyed(this))
      .subscribe();
  }
}
