import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CardComponent } from '@shared/components/card/card.component';

@Component({
  selector: 'napa-unavailable-slot',
  standalone: true,
  imports: [CommonModule, CardComponent],
  templateUrl: './unavailable-slot.component.html',
  styleUrl: './unavailable-slot.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnavailableSlotComponent {}
