export interface NapaDateRange {
  start: Date;
  end: Date;
}

export enum NapaDatePeriod {
  ALL_DATES = 'all-dates',
  THIS_WEEK = 'this-week',
  THIS_MONTH = 'this-month',
}

export type NapaDateRangePeriod = Exclude<NapaDatePeriod, NapaDatePeriod.ALL_DATES>;
