<svg
  width="16px"
  height="16px"
  viewBox="0 0 16 16"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="A-1.0-Sessions" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Sessions&gt;Create-Session-Filledout" transform="translate(-836, -797)">
      <g id="Group-15" transform="translate(132, 657)">
        <g id="Group-7" transform="translate(32, 116)">
          <g id="icon/remove" transform="translate(672, 24)">
            <rect id="Rectangle" x="0" y="0" width="16" height="16"></rect>
            <g
              id="Group"
              transform="translate(8, 8) rotate(-315) translate(-8, -8)translate(5, 5)"
              stroke="#1A7DB8"
              stroke-linecap="round"
              stroke-width="2"
            >
              <line x1="0" y1="3" x2="6" y2="3" id="Path-4"></line>
              <line x1="3" y1="0" x2="3" y2="6" id="Path-11"></line>
            </g>
            <circle id="Oval" stroke="#1A7DB8" stroke-width="2" stroke-linecap="round" cx="8" cy="8" r="7"></circle>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
