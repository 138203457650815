<svg
  width="4px"
  height="18px"
  viewBox="0 0 4 18"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="A-1.0-Sessions" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Sessions" transform="translate(-1232, -163)" fill="currentColor">
      <g id="Group-16" transform="translate(132, 132)">
        <g id="Group-18" transform="translate(1100, 31)">
          <circle id="Oval" cx="2" cy="2" r="2"></circle>
          <circle id="Oval" cx="2" cy="9" r="2"></circle>
          <circle id="Oval" cx="2" cy="16" r="2"></circle>
        </g>
      </g>
    </g>
  </g>
</svg>
